import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/aura-light-green/theme.css";
import "./index.css";
import "primeicons/primeicons.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import store from "./store";
import ProgressSpinner from "primevue/progressspinner";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import Pagination from "v-pagination-3";

import "@vueform/multiselect/themes/default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "../tailwind.config";

import {
  onHandleKeyPress,
  onHandlePaste,
  onHandleKeyUp,
  onHandleKeyDown,
} from "../src/views/P2P/common/inputText.js";
import AppLoader from "./shared/AppLoader.vue";

const mixins = {
  methods: {
    handleDragOver: function (event) {
      event.preventDefault();
      // Handle the drag over event here
    },
    onHandleKeyPress,
    onHandlePaste,
    onHandleKeyUp,
    onHandleKeyDown,
    success: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "success",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    failed: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "error",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    warning: function (message) {
      this.$swal({
        title: message,
        position: "top-end",
        icon: "info",
        toast: true,
        timer: "3000",
        showConfirmButton: false,
      });
    },
    formatDate: function (date, time = true) {
      const u = new Date(date);
      const dd = String(u.getDate()).padStart(2, "0");
      const mm = String(u.getMonth() + 1).padStart(2, "0");
      const yyyy = u.getFullYear();
      const hh = String(u.getHours()).padStart(2, "0");
      const min = String(u.getMinutes()).padStart(2, "0");
      const ss = String(u.getSeconds()).padStart(2, "0");
      if (time) {
        return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
      }
      return `${yyyy}-${mm}-${dd}`;
    },
    showPopup: function (title, body) {
      try {
        return this.$swal({
          title: title,
          text: body,
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#ce1db4",
          cancelButtonColor: "#7e8494",
          confirmButtonText: "Yes!",
          cancelButtonText: "No, cancel!",
        }).then((result) => {
          return result;
        });
      } catch (error) {
        this.warning(error.message);
      }
    },
    copyContent(content) {
      if (content) {
        navigator.clipboard
          .writeText(content)
          .then(() => {
            this.success("Copied");
          })
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      } else {
        this.warning("No item available for copying");
      }
    },
    formatStringToTitleCase(str) {
      if (!str) return ""; // Handle empty or null input

      return str
        .split("_") // Split the string by underscores
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Convert each word to title case
        .join(" "); // Join the words with spaces
    },
    isLogined: function () {
      const token = localStorage.getItem("token");
      return !!token;
    },
  },
};
const app = createApp(App);
// Set the global variable
app.config.globalProperties.$CurrencyQuoteSymbol = [
  // "FAV",
  "USDT",
  "BTC",
  "TRX",
  "ETH",
];
app
  .use(router)
  .use(store)
  .component("VueDatePicker", VueDatePicker)
  .component("pagination", Pagination)
  .mixin(mixins)
  .use(VueSweetalert2)
  .use(PrimeVue)
  .component("ProgressSpinner", ProgressSpinner)
  .component("AppLoader", AppLoader)
  .mount("#app");

export default app;
