import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//local link
// const NODE_URL = "http://192.168.1.27:3000";
// const SOCKET_URL = "ws://192.168.1.27:3000/";
// const BINANCE_SOCKET_URL = "wss://stream.binance.com:9443/ws";
// const CHART_LOGO_LINK = "http://192.168.1.27:8080"

//live link
const NODE_URL  ="https://bn.gdccexchange.com";
const SOCKET_URL = "wss://bn.gdccexchange.com/"
const BINANCE_SOCKET_URL = "wss://stream.binance.com:9443/ws" 
const CHART_LOGO_LINK = "https://gdccexchange.com"

export default class ApiClass {
  static baseSocketnodeUrl = SOCKET_URL;
  static SocketnodeUrl = BINANCE_SOCKET_URL;
  static nodeUrl = `${NODE_URL}/`;
  static authUrl = `${NODE_URL}/auth/`;
  static baseUrl = `${NODE_URL}/api/`;
  static adminUrl = `${NODE_URL}/`;
  static logoUrl = `${CHART_LOGO_LINK}` // used in trading view chart

  //  function for auth routes
  static async getRequest(
    apiUrl,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const result = await axios.get(
        this.nodeUrl + apiUrl,
        this.config(isToken, headers, params)
      );
      return result.data;
    } catch (error) {
      console.error("Unauthorized:", error.message);
    }
  }

  static async postRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    try {
      const response = await axios.post(
        this.authUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      );
      return response?.data;
    } catch (error) {
      if (error.response) {
        if (error.response?.status === 401) {
          this.unauthenticateRedirect();
        } else {
          console.error("API Error:", error.response?.data);
          throw error.response?.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }

  // function for api routes
  static async getNodeRequest(
    apiUrl,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const result = await axios.get(
        this.baseUrl + apiUrl,
        this.config(isToken, headers, params)
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        if (error.response?.status === 401) {
          this.unauthenticateRedirect();
        } else if (error.response?.status === 405) {
          this.maintainceRedirect();
        } else {
          console.error("API Error:", error.response?.data);
          throw error.response?.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }

  static async postNodeRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    try {
      const response = await axios.post(
        this.baseUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      );
      return response?.data;
    } catch (error) {
      if (error.response) {
        if (error.response?.status === 401) {
          this.unauthenticateRedirect();
        } else if (error.response?.status === 429) {
          this.manyRequestError();
        } else if (error.response?.status === 405) {
          this.maintainceRedirect();
        } else {
          console.error("API Error:", error.response?.data);
          throw error.response?.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }

  static async updateNodeRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    try {
      const response = await axios.put(
        this.baseUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      );
      return response?.data;
    } catch (error) {
      if (error.response) {
        if (error.response?.status === 401) {
          this.unauthenticateRedirect();
        } else if (error.response?.status === 405) {
          this.maintainceRedirect();
        } else {
          console.error("API Error:", error.response?.data);
          throw error.response?.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }

  static async deleteNodeRequest(
    apiUrl,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const result = await axios.delete(
        this.baseUrl + apiUrl,
        this.config(isToken, headers, params)
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        if (error.response?.status === 401) {
          this.unauthenticateRedirect();
        } else if (error.response?.status === 405) {
          this.maintainceRedirect();
        } else {
          console.error("Error:", error.response?.data);
          throw error.response?.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }
  // admin route
  static async getAdminRequest(
    apiUrl,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const result = await axios.get(
        this.adminUrl + apiUrl,
        this.config(isToken, headers, params)
      );
      return result.data;
    } catch (error) {
      console.error("Unauthorized:", error.message);
    }
  }

  static config(isToken = true, headers = null, parameters = null) {
    var defaultHeaders = {
      Accept: "application/json",
    };
    var merge = {};
    if (isToken) {
      var token = { Authorization: "Bearer " + localStorage.getItem("token") };

      merge = Object.assign(defaultHeaders, token);
    }
    merge = Object.assign(defaultHeaders, headers);
    return {
      headers: merge,
      params: parameters,
    };
  }

  static unauthenticateRedirect() {
    Swal.fire({
      title: "Unauthorized Access or try to login again",
      position: "top-end",
      icon: "error",
      toast: true,
      timer: 3000,
      showConfirmButton: false,
    });
    localStorage.clear();
    location.replace("/login");
  }

  static manyRequestError() {
    Swal.fire({
      title: "Too many requests, Please wait a while",
      position: "top-end",
      icon: "error",
      toast: true,
      timer: 3000,
      showConfirmButton: false,
    });

    return;
  }

  static maintainceRedirect() {
    Swal.fire({
      title: "System is under maintaince",
      position: "top-end",
      icon: "error",
      toast: true,
      timer: 3000,
      showConfirmButton: false,
    });

    if (!localStorage.getItem("maintenanceRedirect")) {
      localStorage.setItem("maintenanceRedirect", "true");
      location.replace("/maintaince");
    } else {
      localStorage.removeItem("maintenanceRedirect");
      // location.replace("/login");
    }
  }
}
