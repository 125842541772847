<template>
<div>
    <div class="row justify-content-center">
        <div class="col-md-12 col-xxl-10">
            <div class="text-center">
                <ul class="nav nav-pills d-inline-flex gap-3 p-1 mb-5 justify-center  bg-[#25283d]" id="pills-tab" role="tablist">
                    <!-- <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-Derivatives-tab" data-bs-toggle="pill" data-bs-target="#pills-Derivatives" type="button" role="tab" aria-controls="pills-Derivatives" aria-selected="true">
                            Derivatives
                        </button>
                    </li> -->
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-Finance-tab" data-bs-toggle="pill" data-bs-target="#pills-Finance" type="button" role="tab" aria-controls="pills-Finance" aria-selected="false">
                            Finance
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-Moments-tab" data-bs-toggle="pill" data-bs-target="#pills-Moments" type="button" role="tab" aria-controls="pills-Moments" aria-selected="false">
                            Moments
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-Web3-tab" data-bs-toggle="pill" data-bs-target="#pills-Web3" type="button" role="tab" aria-controls="pills-Web3" aria-selected="false">
                            Web3
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-GDCCcard-tab" data-bs-toggle="pill" data-bs-target="#pills-GDCCcard" type="button" role="tab" aria-controls="pills-GDCCcard" aria-selected="false">
                            GDCC Card
                        </button>
                    </li>
                </ul>
            </div>
            <div class="tab-content rounded-4 p-md-5 p-4 d-flex align-items-center cus___tabs" id="pills-tabContent" style="background-color: #131325; ">
                <!-- <div class="tab-pane fade show active w-100" id="pills-Derivatives" role="tabpanel" aria-labelledby="pills-Derivatives-tab" tabindex="0">
                    <div class="text-white rounded">
                        <div class="row">
                            <div class="col-md-6  align-self-center">
                                <div class="flex flex-col justify-center order-1 lg:order-2 w-full">
                                    <p class="text-24 lg:text-32 font-bold">
                                        Perpetual Futures
                                    </p>
                                    <div class="lg:w-96 pt-6">
                                        <ul>
                                            <li class="flex items-start">
                                                <img src="/assets/home/listdots.svg" alt="" width="32" class="mr-1 md:mr-3 md:mt-2" />
                                                <p class="text-20 text-graylight">
                                                    Trade Bitcoin and other cryptos with leverage,
                                                    both long and short
                                                </p>
                                            </li>
                                            <li class="flex items-start mt-6">
                                                <img src="/assets/home/listdots.svg" alt="" width="32" class="mr-1 md:mr-3 md:mt-2" />
                                                <p class="text-20 text-graylight">
                                                    Trade Bitcoin and other cryptos with leverage,
                                                    both long and short
                                                </p>
                                            </li>
                                        </ul>
                                        <div class="mt-16">
                                            <router-link to="#" class="text-white bg-pinkHover py-2 px-4 rounded font-bold block lg:inline-block text-center">Trade Now
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                               
                                <div class="bg-video flex justify-center lg:block">
                                    <video class="bg-video__content" autoplay muted loop width="400">
                                        <source src="/assets/video/pre-future.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->
                <div class="tab-pane active w-100 fade show" id="pills-Finance" role="tabpanel" aria-labelledby="pills-Finance-tab" tabindex="0">
                    <div class="text-white rounded relative">
                        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div class="flex flex-col justify-center order-1 lg:order-2 w-full">
                                <p class="text-24 lg:text-32 font-bold">GDCC Finance</p>
                                <div class="lg:w-96 pt-6">
                                    <ul>
                                        <li class="flex items-start">
                                            <img src="/assets/home/check.svg" alt="" width="24" class="mr-3 mt-2" />
                                            <p class="text-20 text-graylight">
                                                Various ways to earn interest on crypto
                                            </p>
                                        </li>
                                        <li class="flex items-start mt-md-6 mt-2">
                                            <img src="/assets/home/check.svg" alt="" width="24" class="mr-3 mt-2" />
                                            <p class="text-20 text-graylight">
                                                Best-in-class security infrastructure
                                            </p>
                                        </li>
                                        <li class="flex items-start mt-md-6 mt-2">
                                            <img src="/assets/home/check.svg" alt="" width="24" class="mr-3 mt-2" />
                                            <p class="text-20 text-graylight">
                                                Minimal barriers to entry
                                            </p>
                                        </li>
                                    </ul>
                                    <div class="mt-md-16 mt-4 mb-3 mb-md-0">
                                        <router-link to="#" class="text-white bg-pinkHover py-2 px-4 rounded font-bold block lg:inline-block text-center">Trade Now
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="order-2 lg:order-1 ">
                                <TabHover />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane w-100 fade" id="pills-Moments" role="tabpanel" aria-labelledby="pills-Moments-tab" tabindex="0">
                    <div class="text-white rounded relative">
                        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div class="flex flex-col justify-center order-1 lg:order-2 w-full">
                                <p class="text-24 lg:text-32 font-bold">Moments</p>
                                <div class="lg:w-96 pt-6">
                                    <ul>
                                        <li class="flex items-start">
                                            <p class="text-20 text-graylight">
                                                Catch up with the latest updates of the crypto
                                                industry, never miss anything in the world of
                                                crypto again
                                            </p>
                                        </li>
                                    </ul>
                                    <div class="mt-md-16 mt-3">
                                        <router-link to="#" class="text-white bg-pinkHover py-2 px-4 rounded font-bold block lg:inline-block text-center">Read the Latest News
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="order-2 lg:order-1 py-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                                <div class="cus__border rounded-md flex flex-col items-center h-474 bg-transparent justify-between hover:shadow-tabcustom hover:bg-transparent py-4 pl-4">
                                    <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
                                        <source src="/assets/video/moments-1.mp4" type="video/mp4" />
                                    </video>
                                    <div class="flex items-start w-full">
                                        <p class="text-20 mt-6 font-semibold text-start mb-2">
                                            News
                                        </p>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 lg:grid-rows-2 gap-4 h-474">
                                    <div class="cus__border rounded-md flex flex-col items-center bg-transparent justify-between hover:shadow-tabcustom hover:bg-transparent p-4">
                                        <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
                                            <source src="/assets/video/moments-2.mp4" type="video/mp4" />
                                        </video>
                                        <div class="flex items-start w-full">
                                            <p class="text-20 mt-6 font-semibold text-start mb-2">
                                                GDCC Post
                                            </p>
                                        </div>
                                    </div>
                                    <div class="cus__border rounded-md flex flex-col items-center bg-transparent justify-between hover:shadow-tabcustom hover:bg-transparent py-4 pl-4">
                                        <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
                                            <source src="/assets/video/moments-3.mp4" type="video/mp4" />
                                        </video>
                                        <div class="flex items-start w-full">
                                            <p class="text-20 mt-6 font-semibold text-start mb-2">
                                                Live Stream
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane w-100 fade" id="pills-Web3" role="tabpanel" aria-labelledby="pills-Web3-tab" tabindex="0">
                    <div class="text-white rounded relative">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-5 align-self-center">
                                <div class="flex flex-col justify-center order-2 lg:order-1 w-full">
                                    <p class="text-24 lg:text-32 font-bold">Web3</p>
                                    <div class="lg:w-96 pt-6">
                                        <ul>
                                            <li class="flex items-start">
                                                <p class="text-20 text-graylight">
                                                    Decentralized wallets, cross-chain swapping and
                                                    trading of cryptocurrencies, multi-chain NFT
                                                    marketplace and DApps for you
                                                </p>
                                            </li>
                                        </ul>
                                        <div class="mt-md-16 mt-3 mb-4 mb-xl-0">
                                            <router-link to="/" class="text-white bg-pinkHover py-2 px-4 rounded-md font-bold block lg:inline-block text-center">Explore Web3 Now</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-7">
                                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 order-2 lg:order-1 lg:py-5 lg:pt-12">
                                    <div class="relative h-lg-320 lg:inline-block bg-[#25283d]  hover:shadow-tabcustom transition-all  rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                                        <img src="../../public/home/pic_1.webp" alt="" class="" />
                                        <div class="md:w-full">
                                            <div class="flex justify-between">
                                                <div class="flex">
                                                    <router-link to="#">
                                                        <h5 class="text-20 font-semibold text-white mt-8 mb-2">
                                                            Dashboard
                                                        </h5>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <p class="mb-3 mt-1 text-15 text-graylight dark:text-gray-400">
                                                View and manage your asset on various chains.
                                            </p>
                                            <router-link to="/" class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded opacity-0 font-bold group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</router-link>
                                        </div>
                                    </div>

                                    <div class="relative h-lg-320 lg:inline-block bg-[#25283d] hover:shadow-tabcustom transition-all rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                                        <img src="../../public/home/pic_2.webp" alt="" class="" />
                                        <div class="md:w-full">
                                            <div class="flex justify-between">
                                                <div class="flex">
                                                    <router-link to="#">
                                                        <h5 class="text-20 font-semibold text-white mt-8 mb-2">
                                                            Earn
                                                        </h5>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <p class="mb-3 mt-1 text-15 text-graylight dark:text-gray-400">
                                                Aggregate interest-bearing products from multiple
                                                DeFi protocols.
                                            </p>
                                            <router-link to="/" class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded font-bold opacity-0 group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</router-link>
                                        </div>
                                    </div>
                                    <div class="relative h-lg-320 lg:inline-block bg-[#25283d] hover:shadow-tabcustom transition-all rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                                        <img src="../../public/home/pic_3.webp" alt="" class="" />
                                        <div class="md:w-full">
                                            <div class="flex justify-between">
                                                <div class="flex">
                                                    <router-link to="#">
                                                        <h5 class="text-20 font-semibold text-white mt-8 mb-2">
                                                            Trade
                                                        </h5>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <p class="mb-3 mt-1 text-15 text-graylight dark:text-gray-400">
                                                Supporting multiple Dex trading, cross-chain swap.
                                            </p>
                                            <router-link to="/" class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded font-bold opacity-0 group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="tab-pane w-100 fade" id="pills-GDCCcard" role="tabpanel" aria-labelledby="pills-GDCCcard-tab" tabindex="0">
                    <div class="text-white rounded relative py-5">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="flex flex-col justify-center  w-full">
                                <p class="text-24 lg:text-32 font-bold">GDCC Card</p>
                                <div class="lg:w-96 pt-6">
                                    <ul>
                                        <li class="flex items-start">
                                            <p class="text-20 text-graylight">
                                                Visa Debit Card, Enjoy 1% Cashback in USDT
                                            </p>
                                        </li>
                                    </ul>
                                    <div class="mt-md-16 mt-4 mb-4 mb-md-0">
                                        <router-link to="/" class="text-white bg-pinkHover py-2 px-4 rounded-md font-bold block lg:inline-block text-center">Apply Now</router-link>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-7">
                                <div class=" place-self">
                                <div class="">
                                    <!-- <div class="bg-visaCard h-245 w-398 bg-no-repeat bg-cente bg-cover shadow-visatabcustom z-50 absolute right-10 rounded-lg">
                                    </div> -->

                                    <div class="bg_visaCard">
                                        <img src="../../public/assets/home/visa-card.png" alt="" class="rounded-4 ms-auto">
                                    </div>
                                </div>

                                <!-- <div class="flex items-center justify-center relative transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                                    <img src="/assets/home/visa-card-2.webp" alt="" />
                                </div> -->
                            </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import TabHover from "@/components/TabHover.vue";
export default{
    name:'HomeTabs',
    components:{
        TabHover
    }
}
</script>

<style scoped>
.cus___tabs{
    height: 70vh;
}
.h-245{
    height: 245px;
}
.cus__border{
    border: 1px solid #8482965c;
}
.nav-link {
    color: #7e8494;
    font-weight: 600;
    padding: 9px 28px;
    font-size: 17px;
}

.nav-link:hover {
    color: #7e8494;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color:  #07071b ;
    font-weight: 500;
    /* #3c4150 */
}
.bg_visaCard{
    position: relative;
    z-index: 2;
    background: url(../../public/home/gatepay_bg.webp);
    background-size: contain;
}
/* .bg_visaCard::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(40px);
    background: linear-gradient(90deg, rgb(67, 65, 62) 0%, rgb(48, 46, 55) 18%, rgb(123, 87, 120), 50%, rgb(48, 46, 55) 70%, rgb(67, 65, 62) 100%);
    height: 100%;
    width: 100% ;
    z-index: -1;
} */
.bg-visaCard {
    background: url("../../public/assets/home/visa-card.png");
    border-radius: 22px;
    background-size: contain;
    background-repeat: no-repeat;

}
/* @media all and (min-width: 992px) and (max-width: 1199px) {} */
@media all and (min-width: 768px) and (max-width: 1399px) {
    .text-20 {
    font-size: 17px !important;
}
    .cus___tabs{
    height: auto !important;
}
 }

@media all and (min-width: 320px) and (max-width: 767px) {
.cus___tabs{
    height: auto !important;
}
.text-20 {
    font-size: 17px !important;
}
}




</style>