<template>
    <div>
        <nav class="navbar navbar-expand-lg px-3">
            <div class="container-fluid">

                <router-link to="/" class="navbar-brand me-5">
                    <img src="/assets/gdc-logo.png" alt="" width="65">
                </router-link>
                <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button> -->

                <button @click="hidden = !hidden" class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class=""><img :src="hidden ? hide : show"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav gap-3 me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link to="/p2p"
                                class="nav-link text-white text-md flex items-center gap-2 group-hover:text-pinkHover font-medium">
                                <img src="/assets/header/peer-to-peer.png" alt="p2p-icon" class="w-6"
                                    style="filter: invert(1);">
                                P2P
                            </router-link>

                    </li>
                    <li class="nav-item">
                        <router-link to="/spot/BTCUSDT" class="nav-link text-white text-md flex items-center gap-2 group-hover:text-pinkHover font-medium">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="16" viewBox="0 0 448 512">
                                <path d="M160 80c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 352c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-352zM0 272c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 160c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48L0 272zM368 96l32 0c26.5 0 48 21.5 48 48l0 288c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48z" /></svg>
                                Spot Trade 
                        </router-link>
                    </li>

                    </ul>
                    <ul class="d-flex gap-3">
                        <!-- asset -->
                        <li class="group d-none d-lg-block lg:block" v-if="isUserLogined">
                            <div class="relative inline-block text-left" @click="openDropdown('dropdown9')">
                                <div
                                    class="text-white text-sm flex items-center gap-1 group-hover:text-pinkHover font-medium cursor-pointer">
                                    Assets
                                    <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-white"
                                        id="menu-button" aria-expanded="true" aria-haspopup="true"></i>
                                </div>

                                <dropdown id="dropdown9"
                                    class="hide-dropdown absolute top- right-50 z-10 mt-2 origin-top-left rounded bg-white shadow-lg ring-1 py-3 ring-black ring-opacity-5 focus:outline-none hidden -right-6 w-72"
                                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                    <div class="flex px-3">
                                        <div class="w-full">

                                            <router-link to="/myfunds/spot"
                                                class=" hover:bg-bglightpink py-2 rounded transition-all px-1 flex items-center w-full group/p">
                                                <img src="/assets/header/express.png" alt="" width="" class="w-12 mr-4">
                                                <div class="flex flex-col w-full">
                                                    <p
                                                        class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover mb-0">
                                                        Spot</p>
                                                    <p class="text-graylight text-12 mb-0">Deposit&Withdraw</p>
                                                </div>
                                            </router-link>
                                            <router-link to="/transfer"
                                                class=" hover:bg-bglightpink py-2 rounded transition-all px-1 flex items-center w-full group/p">
                                                <img src="/assets/header/express.png" alt="" width="" class="w-12 mr-4">
                                                <div class="flex flex-col w-full">
                                                    <p
                                                        class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover mb-0">
                                                        Transfer</p>

                                                </div>

                                            </router-link>

                                        </div>
                                    </div>
                                </dropdown>
                            </div>
                        </li>


                        <li v-if="!isUserLogined">
                            <router-link to="/login" :class="{ 'bg-pinkHover': isLoginPage }"
                                class="text-white text-sm py-1.5 px-3 rounded">
                                Log In
                            </router-link>
                        </li>

                        <!-- Sign Up Link -->
                        <li v-if="!isUserLogined">
                            <router-link to="/signup" :class="{ 'bg-pinkHover': isSignUpPage }"
                                class="text-white text-sm py-1.5 px-3 rounded">
                                Sign Up
                            </router-link>
                        </li>

                        <!-- profile -->
                        <li class=" md:block" v-if="isUserLogined">
                            <router-link to="/profile">
                                <i class="pi pi-user text-white"></i>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'HeaderView',
    components: {},
    data() {
        return {
            isVisible: false,
            hidden: true,
            hide: require('../assets/icon/menu.svg'),
            show: require('../assets/icon/x.svg'),
            toggle: false,
            menu: {
                "id": "dropdown1",
                "text": "Buy Crypto",
                "items": [{
                    "text": "P2P Trading",
                    "icon": "/assets/header/p2p-trading.png",
                    "description": "Buy and sell crypto with multiple options",
                    "url": "/p2p"
                },],
            },
            copymenu: {
                "id": "dropdown7",
                "text": "Copy",
                "items": [

                    {
                        "text": "Futures Copy Trading",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Grow your wealth by following top futures traders",
                        "url": "#"
                    },
                    {
                        "text": "Spot Copy Trading",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Grasp market trends by following top spot traders",
                        "url": "#"
                    },
                    {
                        "text": "Bot Copy Trading",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Grasp market trends by following top spot traders",
                        "url": "#"
                    }
                ],
            },
            trademenu: {
                "id": "dropdown10",
                "text": "Trade",
                "items": [

                    {
                        "text": "Spot",
                        "icon": "/assets/header/express.png",
                        "description": "Top futures traders",
                        "url": "/spot/BTCUSDT"
                    }
                ],
            },
            momentsmenu: {
                "id": "dropdown2",
                "text": "Moments",
                "items": [{
                    "text": "Gate Post",
                    "icon": "/assets/home/bitcoin-spot.png",
                    "description": "Discover value in crypto",
                    "url": "#"
                },
                {
                    "text": "Live Stream",
                    "icon": "/assets/home/bitcoin-spot.png",
                    "description": "Live crypto market analysis",
                    "url": "#"
                },
                {
                    "text": "Group",
                    "icon": "/assets/home/bitcoin-spot.png",
                    "description": "Chat with crypto traders",
                    "url": "#"
                },
                {
                    "text": "Future Events",
                    "icon": "/assets/home/bitcoin-spot.png",
                    "description": "Calendar of Cryptoassets & Economic Data",
                    "url": "#"
                },
                {
                    "text": "News",
                    "icon": "/assets/home/bitcoin-spot.png",
                    "description": "What is happening in crypto",
                    "url": "#"
                },
                {
                    "text": "Gate Blog",
                    "icon": "/assets/home/bitcoin-spot.png",
                    "description": "Crypto industry articles",
                    "url": "#"
                },
                {
                    "text": "Block Trading",
                    "icon": "/assets/home/bitcoin-spot.png",
                    "description": "Complete large transactions quickly",
                    "url": "#"
                }
                ]
            },
            menuItems: [{

                "id": "dropdown8",
                "text": "Menu Item",
                "items": [{
                    "text": "Exchange",
                    "subItems": [{
                        "text": "Exchange",
                        "description": "Trade over 1,700 cryptocurrencies",
                        "icon": "/assets/header/express.png"
                    },
                    {
                        "text": "Startup",
                        "description": "Token launch and airdrop platform",
                        "icon": "/assets/header/startup.png"
                    },
                    {
                        "text": "Ventures",
                        "description": "Venture capital for Web3.0",
                        "icon": "/assets/header/ventures.png"
                    },
                    {
                        "text": "NFT",
                        "description": "Find and create your own digital assets",
                        "icon": "/assets/header/nft.png"
                    },
                    {
                        "text": "Leaderboard",
                        "description": "Top crypto traders",
                        "icon": "/assets/header/leaderboard.png"
                    },
                    {
                        "text": "Help & Center",
                        "description": "",
                        "icon": "/assets/header/help-center.png"
                    },
                    {
                        "text": "Announcements",
                        "description": "Latest platform updates regarding listings, activities, maintenances and more.",
                        "icon": "/assets/header/announcement.png"
                    }
                    ]
                },
                {
                    "text": "Promotions",
                    "subItems": [{
                        "text": "ETH 2.0 Upgrade",
                        "description": "ETH’s The Merge has finished",
                        "icon": "/assets/header/eth-upgrade.png"
                    },
                    {
                        "text": "Affiliate Program",
                        "description": "Earn commissions by sharing Gate.io with your audience",
                        "icon": "/assets/header/affi-program.png"
                    },
                    {
                        "text": "Influencer Program",
                        "description": "Affiliate Starter Edition",
                        "icon": "/assets/header/influencer-program.png"
                    },
                    {
                        "text": "Referral Program",
                        "description": "Invite your friends for fee disccount and more",
                        "icon": "/assets/header/referral-program.png"
                    },
                    {
                        "text": "Rewards Hub",
                        "description": "Complete tasks and win up to $5,000 prizes",
                        "icon": "/assets/header/rewards-hub.png"
                    },
                    {
                        "text": "Institutional and VIP Services",
                        "description": "Services for quant, market makers and brokers",
                        "icon": "/assets/header/vip-services.png"
                    },
                    {
                        "text": "gate Shop",
                        "description": "Exquisite Gate Peripheral Merchandise",
                        "icon": "/assets/header/gate-shop.png"
                    }
                    ]
                },
                {
                    "text": "Learn & Explore",
                    "subItems": [{
                        "text": "Learn",
                        "description": "Earn crypto while learning",
                        "icon": "/assets/header/learn.png"
                    },
                    {
                        "text": "Gate Blog",
                        "description": "Gain insight on crypto",
                        "icon": "/assets/header/gate-blog.png"
                    },
                    {
                        "text": "Gate Post",
                        "description": "Discover value in crypto",
                        "icon": "/assets/header/gate-post.png"
                    },
                        // {
                        //   "text": "Live Stream",
                        //   "description": "Live crypto market analysis",
                        //   "icon": "/assets/home/header/gate-shop.png"
                        // },
                        // {
                        //   "text": "Group",
                        //   "description": "Chat with crypto traders",
                        //   "icon": "/assets/home/header/gate-shop.png"
                        // },
                        // {
                        //   "text": "News",
                        //   "description": "What is happening in crypto",
                        //   "icon": "/assets/home/header/gate-shop.png"
                        // }
                    ]
                }

                ]
            }],
            financemenu: [{
                "id": "dropdown5",
                "items": [{
                    "financecategory": "Earn",
                    "options": [{
                        "text": "Simple Earn",
                        "description": "Earn interest on your idle funds. Principal and return are guaranteed with always over 100% collateral from your counterparties.",
                        "icon": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "text": "HODL & Earn",
                        "description": "A one stop investment center with a variety of products.",
                        "icon": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "text": "Structured Products",
                        "description": "Pre-packed products that include assets linked to interest and derivatives",
                        "icon": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "text": "Auto-Invest",
                        "description": "One-click investment, flexible portfolio, redeemable and changeable anytime",
                        "icon": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "text": "Dual Investment",
                        "description": "Short-term investment with exposure to two cryptos",
                        "icon": "/assets/home/bitcoin-spot.png"
                    }
                    ]
                },
                {
                    "financecategory": "Loan",
                    "options": [{
                        "text": "Gate Wealth",
                        "description": "Gateway to Wealth",
                        "icon": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "text": "Crypto Loan",
                        "description": "Crypto loan is to pledge one crypto to borrow another",
                        "icon": "/assets/home/bitcoin-spot.png"
                    }
                    ]
                },
                {
                    "financecategory": "More",
                    "options": [{
                        "text": "ETH2.0 Staking",
                        "description": "Stake ETH and get 100% on-chain return. Unstake anytime without delay.",
                        "icon": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "text": "Liquidity Mining",
                        "description": "Earn income from fees by providing Dual-Assets liquidity to the market",
                        "icon": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "text": "Cloud Mining",
                        "description": "Mining cryptocurrency without buying hardware",
                        "icon": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "text": "Slot Auctions",
                        "description": "Participate in parachain auctions & receive rewards.",
                        "icon": "/assets/home/bitcoin-spot.png"
                    }
                    ]
                }
                ]
            }],
            botmenuItems: [{
                "id": "dropdown6",
                "text": "Bots",
                "items": [{
                    "category": "Basic",
                    "options": [{
                        "title": "Spot Grid",
                        "description": "Buy the dips during the downturn and sell all at once to gain profits when the price rebounds",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "title": "Futures Grid",
                        "description": "Take advantage of price fluctuations through multiple trade sides",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "title": "Spot Martingale",
                        "description": "Buy the dip in batches and sell once to gain more with lower risks",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "title": "Smart Rebalance",
                        "description": "Dynamically rebalance your Portfolio. An ideal option for Coin-M trading.",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    }
                    ]
                },
                {
                    "category": "Advanced",
                    "options": [{
                        "title": "Combine Indicator",
                        "description": "Multiple Indicators bring flexible bots",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "title": "Spot-Futures Arbitrage",
                        "description": "Riskless arbitrage through spot & futures hedging",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "title": "Infinite Grid",
                        "description": "No upper limit. A grid bot that always keep running.",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "title": "Margin Grid",
                        "description": "Advanced version of grid trading, using leverage to amplify profits",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    }
                    ]
                },
                {
                    "category": "Explore",
                    "options": [{
                        "title": "More About Bots",
                        "description": "Everything you need to know about bots",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "title": "Trade With Bots",
                        "description": "Bots help you to breeze through trading",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    },
                    {
                        "title": "Bot Pool",
                        "description": "One-click copy, Auto running",
                        "imageSrc": "/assets/home/bitcoin-spot.png"
                    }
                    ]
                }
                ]
            }],
            groupmenu: {

                "dropdown3": {
                    "text": "Trade",
                    "items": [{
                        "text": "Spot",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Trade with advanced order types",
                        "url": "#"
                    },
                    {
                        "text": "Convert",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Trade one crypto for another simply",
                        "url": "#"
                    },
                    {
                        "text": "Margin",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Amplify your trading power with up to 10x leverage",
                        "url": "#"
                    },
                    {
                        "text": "Leveraged Tokens",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Get exposure to leveraged positions simply",
                        "url": "#"
                    },
                    {
                        "text": "Startup",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Token launch and airdrop platform",
                        "url": "#"
                    },
                    {
                        "text": "Bots",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Diverse bots support you earn profits automatically",
                        "url": "#"
                    },
                    {
                        "text": "Block Trading",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Complete large transactions quickly",
                        "url": "#"
                    }
                    ]
                },
                "dropdown4": {
                    "text": "Derivatives",
                    "items": [{
                        "text": "Perpetual Futures",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Perpetual contracts without expiration dates",
                        "url": "#"
                    },
                    {
                        "text": "Delivery Futures",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Weekly or quarterly contracts with expiration dates",
                        "url": "#"
                    },
                    {
                        "text": "Option",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Trade European-style Vanilla Options",
                        "url": "#"
                    },
                    {
                        "text": "Copy Trading",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Automate your trading by copying the top traders",
                        "url": "#"
                    },
                    {
                        "text": "Unified Account",
                        "icon": "/assets/home/bitcoin-spot.png",
                        "description": "Share margin, allow to open new positions with unrealized profits and thus enhance capital efficiency",
                        "url": "#"
                    }
                    ]
                },
                // Add more dropdowns here as needed
            },
            dropdownClass: "hide-dropdown absolute top- right-50 z-10 mt-2 w-100 origin-top-left rounded bg-white shadow-lg ring-1 py-5  ring-black ring-opacity-5 focus:outline-none hidden min-w-1100 -left-32",
            dropdownOrientation: "vertical",
            dropdownTabindex: "-1",
            activeTab: 'exchange',
            dropdownOpen: false,
            BuyCrypto: false,
            isUserLogined: true,
        }
    },

    mounted() {
        this.isUserLogined = localStorage.getItem("token") || false;
    },
    watch: {
        // Watch for changes to login status and update accordingly
        '$route'() {
            this.isUserLogined = localStorage.getItem("token") || false;
        },
    },
    methods: {
        closeToggle() {
            this.hidden = true
            const menuToggle = document.getElementById("navbarNav");
            menuToggle.classList.remove("show");
        },
        toggleSidebar() {
            this.isVisible = !this.isVisible;
        },
        mobilesidebarClose() {
            this.isVisible = false;
        },
        openDropdown(dropdownId) {
            const dropdowns = document.querySelectorAll('.hide-dropdown');
            dropdowns.forEach(dropdown => {
                if (dropdown.id !== dropdownId) {
                    dropdown.classList.add("hidden");
                }
            });
            document.getElementById(dropdownId).classList.toggle("hidden");
        },
        hideDropdowns() {

            var hideDropdowns = document.getElementsByClassName('hide-dropdown');
            for (var i = 0; i < hideDropdowns.length; i++) {
                hideDropdowns[i].classList.add("hidden");
            }
        }
    },
    computed: {
        isLoginPage() {
            return this.$route.path === '/login';
        },
        isSignUpPage() {
            return this.$route.path === '/signup';
        },
    },

}
</script>

<style lang="scss" scoped>
.navbar {
    background: #000;
    // position: fixed;
    // top: 0;
    // width: 100%;
    z-index: 99;

// width: 100%;
//     position: fixed;
//     background: #ffffff05;
//     z-index: 99;
//     backdrop-filter: blur(14px);
}

.nav-link {
    color: #fff !important;
}

.collapse {
    visibility: visible;
}

// @media all and (min-width:320px) and (max-width:767px) {

//     .navbar {
//     position: fixed;
//     top: 0;
//     width: 100%;
// }
// }</style>
