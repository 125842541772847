<template>
  <div>
    <div class="flex gap-1 lg:gap-10 flex-col xl:flex-row w-full">
      <div class="xl:flex-1 h-600">
        <div class="card mt-6 rounded-xl border border-gray-100 p-2 md:p-6 w-full h-full relative">
          <div>
            <ul
              class="flex overflow-x-auto w-100 text-12 md:text-16 font-medium text-center text-gray-500 dark:text-gray-400 scrollbar-hide pl-0">
              <li v-for="(tab, index) in tabs" :key="index" class="me-2 cursor-pointer p-2">
                <p class="mb-0 my-2" :class="['flex items-center gap-1 px-4 py-2 rounded', {
                  ' text-pinkHover bg-bglightpink border-pinkHover rounded': index === selectedTab,
                  'hover:text-gray-900  dark:hover:bg-gray-800 dark:hover:text-white': index !== selectedTab
                }]" @click="selectTab(index)">
                  {{ tab.label }}
                  <span v-if="tab.label === 'Leaderboard'">
                    <img src="/assets/home/table-dec.svg" alt="" width="20" class="">
                  </span>
                </p>
              </li>
            </ul>
            <div class="tab-content mt-2" v-if="marketTable">
              <div class="relative overflow-x-auto  ml-md-5">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class=" text-10 lg:text-sm text-gray-400   dark:text-gray-400 ">
                    <tr>
                      <th scope="col" class="pl-4 py-2 font-normal"> Coin </th>
                      <th scope="col" class=" py-2 font-normal">Price</th>
                      <th scope="col" class=" py-2 font-normal hidden sm:block">24h Trading Volume</th>
                      <th scope="col" class="py-2 font-normal">24h Change</th>
                      <th scope="col" class=" py-2 font-normal hidden sm:block"><span class="sr-only"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(product, index) in filtered" :key="index"
                      class="bg-white hover:bg-gray-50 dark:hover:bg-gray-50 border-b">

                      <td scope="row"
                        class="px-2 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white  text-12 md:text-14 uppercase flex items-center">
                        <img :src="product.image" alt="" class="w-5 mr-3 mt-1 h-5">
                        <div class="ml-3">
                          {{ product.currency }}
                          <p class="!text-gray-600 !font-medium !capitalize ">
                            {{ product.pair_with }}
                          </p>
                        </div>
                      </td>

                      <td class="py-4  text-gray-900 font-semibold text-12 md:text-14">
                        <p class="text-12 mt-2 font-bold mb-0" :style="{
                          color:
                            product.priceClr == 2 ? 'var(--red)' : 'var(--green)',
                        }">
                          {{ product.price ? Number(product.price).toFixed(4) : 0 }}</p>
                      </td>

                      <td class="py-4  text-gray-900 font-semibol text-12 md:text-14 hidden sm:block">{{
                        product?.volume ? product?.volume : 0 }}
                      </td>

                      <td class="py-4  font-semibold text-red-500 text-12 md:text-14">
                        <div class="flex gap-1">
                          <div class="change_icon" :style="{
                            color:
                              product.change > 0 ? 'var(--green)' : 'var(--red)',
                          }">
                            <i v-if="product.change > 0" class="pi pi-sort-up-fill"></i>
                            <i v-else class="pi pi-sort-down-fill"></i>
                            {{ product.change ? product.change : 0 }}%
                          </div>
                        </div>
                      </td>

                      <td class="py-4  text-right">
                        <router-link :to="`/spot/${product.currency}${product.pair_with}`"
                          class="font-xl text-pinkHover bg-bglightpink  rounded hover:underline px-5 py-2 font-bold text-12 md:text-14 hidden lg:block width-fit-content">Trade</router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="flex-none w-full xl:w-30 h-600 ">
        <div class="card mt-6 rounded-xl border border-gray-100 p-2 md:p-6 h-full relative">
          <div>
            <ul
              class="flex w-100 text-12 md:text-16 font-medium text-center text-gray-500 dark:text-gray-400 cursor-pointer pl-0">
              <li class="me-2 p-2">
                <p
                  class="mb-0 my-2 p-2 text-pinkHover bg-bglightpink  border-pinkHover rounded hover:text-gray-900  dark:hover:bg-gray-800 dark:hover:text-white">
                  Top Gainers
                </p>
              </li>
            </ul>

            <div class="tab-content mt-4" v-if="popularmarketTable">
              <div class="relative overflow-x-auto scrollbar-hide">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-10 lg:text-sm text-gray-400   dark:text-gray-400 capitalize">
                    <tr class="">
                      <th scope="col" class="px-2 py-2 font-normal">Currency</th>
                      <th scope="col" class="px-2 py-2 font-normal">Price</th>
                      <th scope="col" class="px-2 py-2 font-normal">Change</th>
                    </tr>
                  </thead>
                  <tbody v-if="gainers.length > 0">
                    <tr v-for="(product, index) in gainers" :key="index"
                      class="bg-white hover:bg-gray-50 dark:hover:bg-gray-50 border-b">
                      <td scope="row"
                        class="px-2 py-4 font-bold text-gray-900 whitespace-nowrap dark:text-white  text-12 md:text-14 uppercase flex items-center">
                        <img :src="`/assets/home/${product?.userimg}`" class="border-round w-8 h-8 rounded-full" />
                        <div class="ml-3">
                          {{ product?.symbol || 'N/A' }}

                        </div>
                      </td>
                      <!-- <td class="px-6 py-4">{{ product.coin }}</td> -->
                      <td class="px-2 py-4  text-gray-900 font-semibold text-12 md:text-14">{{ product?.lastPrice || 0
                        }}</td>
                      <td class="px-2 py-4  font-semibold text-red-500 text-12 md:text-14">{{
                        product?.priceChangePercent ? product?.priceChangePercent : 0 }}%

                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="py-5"></td>
                    </tr>
                    <tr>
                      <td class="py-5"></td>
                    </tr>
                    <tr>

                      <td colspan="5" class="text-center py-1">No Result Found</td>
                    </tr>
                    <tr>
                      <td class="py-5"></td>
                    </tr>
                    <tr>
                      <td class="py-5"></td>
                    </tr>
                    <tr>
                      <td class="py-5"></td>
                    </tr>
                    <tr>
                      <td class="py-2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClass from '@/api';
import exactMath from "exact-math";
const mathConfig = {
  returnString: true,
  eMinus: Infinity,
};
export default {

  data() {
    return {
      rightactiveTab: 0,
      selectedTab: 0,
      pselectedTab: 0,
      marketTable: [],
      popularmarketTable: [],
      tabs: [
        {
          label: 'Spot', data: [
            { coin: 'gt', coinSubHeading: 'GateToken', userimg: 'user.png', tradingval: '	563.53M', tradingchange: '-5.24%', price: '$2999' },
            { coin: 'bts', coinSubHeading: 'GateToken', userimg: 'user.png', tradingval: '54.53M', tradingchange: '-34.24%', price: '$1999' },
            { coin: 'eth', coinSubHeading: 'GateToken', userimg: 'user.png', tradingval: '23.53M', tradingchange: '-5.24%', price: '$99.565' },
            { coin: 'ena', coinSubHeading: 'GateToken', userimg: 'user.png', tradingval: '5.53M', tradingchange: '-22.24%', price: '$99.000' },
            { coin: 'bome', coinSubHeading: 'GateToken', userimg: 'user.png', tradingval: '65.53M', tradingchange: '-5.24%', price: '$99.409' }

          ]
        },
      ],
      populartabs: [],
      core_data: [],
      gainers: [],
      losers: [],
      marketTickers: [],
      cryptocurrencies_dataUSDT: [],
      filtered: [],
      tickers: [],
      own_tickers: [],
      myConnection: "",
    }
  },
  computed: {
    selectedTabData() {
      return this.tabs[this.selectedTab].data;
    },
  },
  mounted() {
    this.getTopGainers();
    this.getCryptoList();
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
    },
    pselectTab(index) {
      this.pselectedTab = index;
    },
    async getTopGainers() {
      try {
        const response = await ApiClass.getAdminRequest('dashboard/market-gainers', false);
        if (response?.status_code == '1') {
          this.core_data = response?.data.core_data.slice(0, 5);
          this.gainers = response?.data.gainers.slice(0, 5);
          this.losers = response?.data.losers.slice(0, 5);
          this.marketTickers = response?.data.tickers.slice(0, 5);
          return;
        }
      } catch (error) {
        return this.failed(error.message);
      }
    },
    async getCryptoList() {
      try {
        const response = await ApiClass.getNodeRequest('list-crypto/get');
        if (response?.status_code === "1") {
          const data = response?.data;
          this.cryptocurrencies_dataUSDT = data.USDT.slice(0, 5);
          this.tickers = response?.tickers;
          this.webSoc();
          this.loading = false;
          var pair_with = 'USDT';
          this.newTab(pair_with);
          this.loader = false;

          return;
        }
      } catch (error) {
        return this.failed(error.message);
      }
    },
    newTab(pair) {
      const dataMapping = {
        USDT: this.cryptocurrencies_dataUSDT,
      };
      this.cryptocurrencies_data = dataMapping[pair] || [];
      this.filtered = this.cryptocurrencies_data;
      const handleMessage = (event) => {
        const soc_data = JSON.parse(event.data);
        const conversionData = this.$store.getters.getConversionData.rate;
        const updateSymbolIfNeeded = (symbol) => symbol.includes("INR") ? symbol.replace("INR", "USDT") : symbol;

        const updateCryptocurrencyData = (data) => {
          this.cryptocurrencies_data.forEach((o) => {
            if (o.symbol.includes("INR")) {
              const sym = updateSymbolIfNeeded(o.symbol);

              if (sym === data.s) {
                Object.assign(o, {
                  change: parseFloat(data.P).toFixed(2),
                  high: parseFloat(exactMath.mul(data.h, conversionData, mathConfig)),
                  low: parseFloat(exactMath.mul(data.l, conversionData, mathConfig)),
                  volume: parseFloat(data.v),
                  priceClr: o.price < parseFloat(exactMath.mul(data.c, conversionData, mathConfig)) ? 1 : 2,
                  price: parseFloat(exactMath.mul(data.c, conversionData, mathConfig)),
                });
              }
            }
            if (o.symbol === data.s) {
              Object.assign(o, {
                change: parseFloat(data.P).toFixed(2),
                high: parseFloat(data.h),
                low: parseFloat(data.l),
                volume: parseFloat(data.v),
                priceClr: o.price < data.c ? 1 : 2,
                price: parseFloat(data.c),
              });
            }
          });
        };

        updateCryptocurrencyData(soc_data);
      };
      this.connection.onmessage = (event) => handleMessage(event);
      this.myConnection.onmessage = (event) => handleMessage(event, true);
    },
    webSoc() {

      const ticker_subs = this.tickers.map((v) => {
        return v.toLowerCase() + "@ticker";
      });
      const ticker_subs1 = this.own_tickers.map((v) => {
        return v.toLowerCase() + "@ticker";
      });
      this.connection = new WebSocket(ApiClass.SocketnodeUrl);
      this.myConnection = new WebSocket(ApiClass.SocketnodeUrl);

      var ccStreamer = this.connection;
      var ccStreamer1 = this.myConnection;
      this.connection.onopen = function onStreamOpen() {
        var subRequest2 = {
          method: "SUBSCRIBE",
          params: ticker_subs,
          id: 1,
        };
        ccStreamer.send(JSON.stringify(subRequest2));
      };
      this.myConnection.onopen = function onStreamOpen() {
        var subRequest = {
          method: "ADD",
          params: ticker_subs1,
        };
        ccStreamer1.send(JSON.stringify(subRequest));
      };

      this.connection.onclose = function () {
        console.log(
          "Successfully disconnected to the echo websocket server..."
        );
      };
      this.myConnection.onclose = function () {
        console.log(
          "Successfully disconnected to the echo Exchange Sidebar..."
        );
      };


    },
  }
}

</script>
