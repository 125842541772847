<template>
  <div class="gate grid grid-cols-1 md:grid-cols-2 gap-6">
    <div v-for="(item, index) in boxItems" :key="index" class="box_wrap  bg-[#25283d] rounded-md flex flex-col items-center justify-center py-9 hover:shadow-tabcustom group"
         @mouseover="changeImgPath(index)" @mouseleave="resetImgPath(index)">
      <img :src="item.Src" :alt="item.name" width="48" height="48" class="mr-2 rounded-full group-hover:transform  group-hover:scale-150 transition-all ease-in-out">
      <p class="text-20 mt-6 font-semibold">{{ item.name }}</p>
      <p class="text-medium text-graylight">{{ item.apr }}</p>
    </div>
  </div>
</template>


  
  <script>
  export default {
    data() { 
      return {
        boxItems: [
          {
            name: 'ETH',
            Src: '/assets/home/eth.webp',
            imgPath: '/assets/home/eth.webp',
            hoverImgPath: '/assets/home/eth-hover.webp',
            apr: 'APR 0.50%-13.00%'
          },
          {
            name: 'USDT',
            Src: '/assets/home/usdt.webp',
            imgPath: '/assets/home/usdt.webp',
            hoverImgPath: '/assets/home/usdt-hover.webp',
            apr: 'APR 0.50%-13.00%'
          },
          {
            name: 'BTC',
            Src: '/assets/home/btc.webp',
            imgPath: '/assets/home/btc.webp',
            hoverImgPath: '/assets/home/btc-hover.webp',
            apr: 'APR 0.50%-13.00%'
          },
          {
            name: 'GT',
            Src: '/assets/home/gt.webp',
            imgPath: '/assets/home/gt.webp',
            hoverImgPath: '/assets/home/gt-hover.webp',
            apr: 'APR 0.50%-13.00%'
          }
        ]
      };
    },
    methods: {
      changeImgPath(index) {
        this.boxItems[index].Src = this.boxItems[index].hoverImgPath;
      },
      resetImgPath(index) {
        this.boxItems[index].Src = this.boxItems[index].imgPath;
      }
    }
  };
  </script>
  <!-- <style>
  .hover-scaled:hover {
  transform: scale(1.5);
}
</style> -->
  