// tailwind.config.js
module.exports = {
  purge: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: { 
      fontFamily: {
        'sans': ['"Gate sans"'],
      },
      lineClamp: {
        1: '1',
        2: '2', 

      },
      colors: {
        'bglightpink': '#ce1db40f',
        'pinkborder': '#ce1db4',
        'darktext': '#04091a',
        'darkColor': '#0c0c0f',
        'bgdarkColor': '#1d1d24',
        'pinkHover': '#ce1db4',
        'secondarydark': '#20232b',
        'lightdark': '#3c4150',
        'graylight': '#7e8494',
        'main-gray': '#f8f9fb',
        'btn-gray': '#ebedf2',
        'tab-gray': '#14151a',
        'redcolor': '#f2495e', 
        'greencolor': '#1bb273', 
        'bargray': '#acb2bf',
        'bg-input': '#f2f3f7',
        'text-muted': '#70798c',
        'modalBG': '#000000b5',
        'bodyColor': '#f8f9fb',
        'SideHover': '#ce1db40f',
        'LightGrey': '#8d93a6',
        'identBg': '#11ad7a14',
        'identtext': '#11ad7a',
        'disableBG': '#e6e9f2',
        'disabletext': '#b8bdcc',
        'secBorder': '#eef1fa',
        'SecuBg': '#F5F5F7',
        'InputBorder': '#dadde5',
        'spotBg' : '#f8fafd',
        'usdtext' : '#565d73',
        'Tborder': '#e2dcdc',
        'noticeBg': '#fffcf3',
        'lightgray':'#31343c',
      },
      fontSize: {
        '7':'7px', 
        '10':'10px', 
        '12':'12px', 
        '13':'13px',
        '14':'14px', 
        '15':'15px',
        '16':'16px', 
        '18':'18px', 
        '40':'40px', 
        '20':'20px',
        '26':'26px', 
        '24':'24px',
        '28':'28px', 
        '32':'32px', 
        '48':'48px',
    },
    padding: {
      '6.5': '26px',
    },
    margin:{
      '40': '40px',

    },
    height: {
      '7c': '7px',
      '16': '16px',
      '17': '17px',
      '64': '64px',
      '80': '80px',
      '200': '200px', 
      '230': '230px',
      '340': '340px', 
      '151': '151px', 
      '400': '400px',   
      '585': '585px',
      '568': '568px',
      '487': '487px',
      '826': '826px',
      '432': '432px',
      '320': '320px',
      '30T': '30%',
      '258': '258px',
      '600': '600px', 
      '900': '900px',
      '50': '50px',


    },
    width: {
      '7c': '7px',
      '80': '80px',
      '200': '200px',
      '260': '260px',
      '230': '230px',
      '300': '300px',
      '304': '304px',
      '474': '474px',
      '320': '320px',
      '356': '356px',
      '72c': '72px',
      '52c': '52px',
      '70': '70%',
      '75':'75%',
      '55': '55%',
      '30': '30%',
      '456': '456px',
      '720': '720px',
      '608': '608px',
      '398': '398px',
      '238': '238px',
      '118': '118px',
      '624': '624px',
      '820': '820px',
      '500': '500px',
      '764': '764px',

    },
    maxWidth: {
      '500': '500px',
    },
    minWidth: {
      '1100': '1100px',
      '800': '800px',

    },
    borderRadius:{
      '5': '5px',
      '4': '4px',
      '6P': '6.8px',
    },
    boxShadow: {
      'custom': 'rgba(13, 31, 66, 0.07) 0px 7px 20px',
      'tabcustom': 'rgb(38, 57, 77) 0px 20px 30px -10px;',
      'visatabcustom': 'rgb(94, 93, 106) 0px 16px 124px 0px;',
      'secShadow': '0px 4px 16rem rgba(23, 56, 153, 0.06)',
      'secushad': 'rgba(13, 31, 66, 0.07) 0px 0px 26px 0px',
      'coinselect': '0px 4px 16px 0px #1738990F',
      'loginbox' :"0px 7px 20px 0px rgba(13, 31, 66, 0.07)",

    },

    filter: {
      'custom': 'brightness(0) saturate(100%) invert(28%) sepia(97%) saturate(3789%) hue-rotate(222deg) brightness(92%) contrast(97%)',
    },
    backgroundImage: {
      'tab': "linear-gradient(90deg, rgb(0, 252, 189), rgb(204, 21, 126))",
      'tabbtn': "linear-gradient(270deg, #16d9d9 0, #17e5a1 100%))",
      'depositbg':"linear-gradient(#2354e60f 0%, #fff, #fff,#fff,#fff)",
      'listingBg' : "url('/public/assets/listing/listingpage.png')",
    },
    inset: {
      '30': '30%',
    },
    blur: {
      '140b': '140px',
    },
    zIndex: {
      '999': '999',
    }
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
}