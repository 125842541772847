<template>
    <span class="text-center">
        <ProgressSpinner strokeWidth="2" style="width: 2rem;height:fit-content" strokeColor="white" />
    </span>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name: 'AppLoader',
    components: {
        ProgressSpinner
    },
    props: {
        loading: {
            type: Boolean,
            default: true
        },
    }
};
</script>
<style scoped>
:deep(.p-progress-spinner-circle) {
    stroke: white !important;
}
</style>