<template>
  <div>
    <section class="banner_sec d-flex align-items-center justify-content-center">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-9 col-xl-7 col-xxl-6 align-self-center">
            <div class="px-0 px-md-5 px-lg-0">
              <h1 class="mb-3 banner_heading text-white">
                The Future of Crypto Trading Starts Here
              </h1>
              <p class="mb-3 text-white font-semibold banner_p">
                Trade over 50+ cryptocurrencies safely, quickly, and easily
              </p>
              <div class="flex mb-3 align-middle">
                <img src="/assets/home/gift-home-solid.svg" alt="" width="20" class="" />
                <p class="text-gray-400 ml-2">Trade. Invest. Grow.</p>
              </div>

              <div class="input-group w-50">
                <input type="text" placeholder="Email or phone" v-model="email"
                  class="form-control border-0 shadow-none bg-gray-200 rounded-l-5 text-black"
                  style="min-height: 53px" />
                <span
                  class="input-group-text bg-pinkHover px-4 border border-pinkHover text-white font-bold rounded whitespace-nowrap"
                  @click="gotoSignup">
                  Sign Up
                </span>
              </div>

              <!-- <div class="grid gap-x-6 grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4 mt-14 xl:mt-20">
                          <div>
                              <p class="text-16 md:text-20 lg:text-32 font-semibold whitespace-pre text-white">
                                  $1B
                              </p>
                              <p class="text-14 text-gray-400 whitespace-pre">
                                  24h Trading Volume
                              </p>
                          </div>
                          <div>
                              <p class="text-16 md:text-20 lg:text-32 font-semibold text-white">
                                  10+
                              </p>
                              <p class="text-14 text-gray-400 whitespace-pre">
                                  Cryptocurrencies Listed
                              </p>
                          </div>
                          <div class="mt-md-0 mt-3 mt-xl-4 mt-xxl-0">
                              <p class="text-16 md:text-20 lg:text-32 font-semibold text-white">
                                  1M+
                                
                              </p>
                              <p class="text-14 text-gray-400 whitespace-pre">
                                  Registered Users
                              </p>
                          </div>
                          <div class="mt-md-0 mt-3 mt-xl-4 mt-xxl-0">
                              <p class="text-16 md:text-20 lg:text-32 font-semibold text-white">
                                  10.78%
                               
                              </p>
                              <p class="text-14 text-gray-400">Simple Earn APR</p>
                          </div>
                      </div> -->
            </div>
          </div>

          <div class="col-md-6 col-lg-3 col-xl-5 col-xxl-6 align-self-center">
            <div class="banner_img mt-5 mt-md-0">
              <!-- <img
                src="../../public/pixelpulse-logo.png"
                alt="img"
                class="mx-auto"
              /> -->
              <img src="../../public/111.png" alt="img" class="mx-auto" style="width: 75%;" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner section  -->

    <section class="news_sec pb-2">
      <div class="container">
        <div
          class="h-14 overflow-hidden justify-between bg-gray-50 items-center py-2 px-md-6 px-3 d-flex rounded border border-gray-100 hidden md:flex">
          <div class="flex items-center w-1/2 mr-5 border-r-2 border-gray-200 mx-0 mx-lg-2">
            <img src="/assets/home/comment-dots-regular.svg" alt="" width="20" class="mr-4" />
            <p :class="{ 'animate-roll-in': showAnimation }" class="mr-2 line-clamp-1 text-14">
              Unlock the Power Trading: Exchange Assets Instantly with Our
              Cutting-Edge Platform
            </p>
          </div>
          <div class="flex items-center w-1/2 ml-5 mx-0 mx-lg-3">
            <p :class="{ 'animate-roll-in': showAnimation }" class="flex-1 line-clamp-1 text-14 ms-2 ms-md-0">
              Trade with Confidence: Access Real-Time Data, Advanced Tools, and
              Competitive Rates
            </p>
            <img src="/assets/home/bars-solid.svg" alt="" width="16" class="ml-4" />
          </div>
        </div>
      </div>
    </section>
    <!-- News Section  -->

    <section>
      <div class="container">
        <div class="today-market-table">
          <div class="flex sm:items-center sm:justify-between flex-col sm:flex-row">
            <p class="section_heading">Crypto Market Today</p>
          </div>
          <CryptoMarketVIew />
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="flex items-center justify-between mb-3">
              <p class="section_heading">Activities</p>
            </div>
            <div>
              <carousel class="p-0" :items-to-show="4" :breakpoints="breakpoints1" :transition="500" :autoplay="2000"
                :wrap-around="true">
                <slide v-for="(option, index) in activityList" :key="index">
                  <div class="main-img position-relative group">
                    <div class="position-relative">
                      <img :src="`${option.image}`" :alt="option.image" class="rounded-lg w-100" />
                      <!-- <img src="../../public/home/slider1.png" alt="image image" class="rounded-lg w-72"> -->
                    </div>
                    <div
                      class="industry__content position-absolute flex justify-start items-start w-72 h-100 overflow-hidden shadow-md p-3 mb-4 rounded-b-lg transition-all opacity-0 group-hover:opacity-100">
                      <p class="fs-5 fw-normal lh-base line-clamp-2 font-semibold">
                        {{ option.link }}
                      </p>
                    </div>
                  </div>
                </slide>
                <template #addons="{ slidesCount }">
                  <Navigation v-if="slidesCount > 1" />
                </template>
              </carousel>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="py-5 mt-3">
              <p class="section_heading mb-3">How to Get Started</p>

              <transition-group name="step" appear @before-enter="beforeEnter" @enter="enter">
                <div class="row">
                  <div class="col-md-6 col-lg-4 mb-4 mb-lg-0" v-for="(step, index) in steps" :key="index"
                    :data-index="index">
                    <div
                      class="get__start p-6 border border-gray-200 rounded-lg hover:shadow-custom hover:bg-main-gray transition-all hover:border-main-gray step-box">
                      <div class="">
                        <img :src="step.image" alt="" class="mb-3 w-52c md:w-72c" />
                        <div class="flex flex-col lg:inline-block">
                          <h5
                            class="mb-2 text-20 md:text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                            {{ step.title }}
                          </h5>
                          <p class="text-gray-400 mb-4">
                            {{ step.description }}
                          </p>
                        </div>
                      </div>
                      <template v-if="step.buttons">
                        <div class="flex flex-wrap md:flex-nowrap :md-justify-center :lg-justify-start gap-2">
                          <router-link v-for="(button, buttonIndex) in step.buttons" :key="buttonIndex"
                            :data-index="index" :to="getRedirectLink(button)"
                            class="cus__btn text-white bg-pinkHover py-1 px-4 rounded font-semibold whitespace-pre inline-block md:block lg:inline-block text-center">{{
                            button.text }}</router-link>
                        </div>
                      </template>
                      <template v-else>
                        <router-link :to="step.buttonLink"
                          class="cus__btn text-white md:text-base bg-pinkHover py-1 px-4 rounded font-semibold block md:inline-block text-center">{{
                          step.buttonText }}</router-link>
                      </template>
                    </div>
                  </div>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="why_us pt-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="section_heading text-center">Why Choose GDCC Exchange?</p>
          </div>
        </div>
        <!-- rounded-lg bg-main-gray border border-gray-100 -->
        <div class="row justify-content-center reviews mt-6 px-6 py-8">
          <div class="col-md-6 col-lg-4">
            <div class="why__box p-xxl-4 py-4 p-2 mx-xxl-2 mt-xxl-2 text-center mb-5 rounded-4">
              <div class="mb-3 text-center">
                <img src="../../public/home/icon1.png" alt="image" style="height: 100px;" class="mx-auto">
              </div>
              <div class="px-xxl-4 px-2">
                <h6 class="mb-3 text-20 md:text-2xl font-bold text-white">
                  Ultra-Secure Trading
                </h6>
                <p class="text-[#7e8494] mb-3 mb-md-0">
                  Your security is our priority. With industry-leading encryption and multi-layered protections, GDCC
                  Exchange ensures your assets and personal information are safe around the clock.
                </p>
              </div>
            </div>
          </div>


          <div class="col-md-6 col-lg-4">
            <div class="why__box p-xxl-4 py-4 p-2 m-xxl-2 text-center mb-4 rounded-4">
              <div class="mb-3 text-center">
                <img src="../../public/home/icon2.png" alt="image" style="height: 100px;" class="mx-auto">
                <!-- <span class="text-20 md:text-2xl font-semibold text-[#7e8494]">02</span> -->
              </div>
              <div class="px-xxl-4 px-2">
                <h6 class="mb-3 text-20 md:text-2xl font-bold text-white">
                  User-Centric Interface
                </h6>
                <p class="text-[#7e8494] mb-3 mb-md-0">
                  Our intuitive platform makes it easy for anyone to trade. Enjoy seamless navigation, real-time charts,
                  and
                  advanced trading tools designed to enhance your experience.
                </p>
              </div>
            </div>
          </div>



          <div class="col-md-6 col-lg-4">
            <div class="why__box p-xxl-4 py-4 p-2 m-xxl-2 text-center mb-4 rounded-4">
              <div class="mb-3 text-center">
                <img src="../../public/home/icon3.png" alt="image" style="height: 100px;" class="mx-auto">
                <!-- <span class="text-20 md:text-2xl font-semibold text-[#7e8494]">03</span> -->
              </div>
              <div class="px-xxl-4 px-2">
                <h6 class="mb-3 text-20 md:text-2xl font-bold text-white">
                  Extensive Asset Selection
                </h6>
                <p class="text-[#7e8494] mb-3 mb-md-0">
                  Trade a wide range of cryptocurrencies including Bitcoin (BTC), Ethereum (ETH), and hundreds of
                  altcoins,
                  with high liquidity and tight spreads.
                </p>
              </div>
            </div>
          </div>


          <div class="col-md-6 col-lg-4">
            <div class="why__box p-xxl-4 py-4 p-2 m-xxl-2 text-center mb-4 rounded-4">
              <div class="mb-3 text-center">
                <img src="../../public/home/icon4.png" alt="image" style="height: 100px;" class="mx-auto">
                <!-- <span class="text-20 md:text-2xl font-semibold text-[#7e8494]">04</span> -->
              </div>
              <div class="px-xxl-4 px-2">
                <h6 class="mb-3 text-20 md:text-2xl font-bold text-white">
                  Low Fees, High Rewards
                </h6>
                <p class="text-[#7e8494] mb-3 mb-md-0">
                  Get more value out of every trade. With some of the lowest fees in the industry, you can trade
                  efficiently
                  and maximize your gains.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="why__box p-xxl-4 py-4 p-2 m-xxl-2 text-center mb-4 rounded-4">
              <div class="mb-3 text-center">
                <img src="../../public/home/icon5.png" alt="image" style="height: 100px;" class="mx-auto">

              </div>
              <div class="px-xxl-4 px-2">
                <h6 class="mb-3 text-20 md:text-2xl font-bold text-white">
                  24/7 Support
                </h6>
                <p class="text-[#7e8494] mb-3 mb-md-0">
                  Our dedicated support team is always here to help. Whether you need assistance or have questions,
                  we’ve
                  got you covered—anytime, anywhere.
                </p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>

    <section class="dark__bg py-5 d-flex align-items-center justify-content-center">
      <div class="container">
        <HomeTabs />
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- crypto lifecycle -->
            <div class="lifestyle">
              <div class="mb-6">
                <p class="section_heading">Crypto Lifestyle</p>
              </div>
            </div>

            <div class="">
              <div class="reviews mt-6 bg-main-gray px-6 py-8 rounded-lg border border-gray-100">
                <div class="lg:flex">
                  <div class="flex-1 group">
                    <carousel class="p-0" :items-to-show="1.5" :breakpoints="breakpoints2" :transition="500"
                      :wrap-around="true">
                      <slide v-for="(option, index) in reviewoptions" :key="index">
                        <div
                          class="bg-white main-img px-md-6 px-0 mb-4 rounded-lg flex items-center justify-center flex-col py-md-10 py-3 md:mx-6">
                          <div>
                            <img :src="`/assets/home/${option.img}`" :alt="option.img"
                              class="rounded-full w-80 h-80 object-cover" />
                          </div>
                          <div>
                            <p class="fs-5 text-graylight mt-3 mb-6 font-medium">
                              {{ option.name }}
                            </p>
                          </div>
                          <div class="w-full">
                            <p class="text-18 text-start" style="font-weight: 500">
                              {{ option.title }}
                            </p>
                          </div>
                        </div>
                        <div class="custom-pagi">
                          <!-- <pagination />  -->
                        </div>
                      </slide>
                      <template #addons="{ slidesCount }">
                        <div>
                          <Navigation v-if="slidesCount > 1"
                            class="my-auto bg-btn-gray bg-opacity-75 rounded px-1 py-1 opacity-0 group-hover:opacity-100">
                          </Navigation>
                        </div>
                      </template>
                    </carousel>
                  </div>
                  <div class="lg:w-96 lg:pl-16 md:flex lg:inline-block mt-4">
                    <div class="flex items-center justify-center">
                      <img src="/assets/home/media-share-pic.png" alt="" width="" class="" />
                    </div>
                    <div class="lg:w-full flex items-center lg:justify-between flex-col md:w-96 lg:ml-0">
                      <div class="w-full flex items-center justify-between py-10">
                        <img src="/assets/home/facebook.svg" alt="" width="32" />
                        <img src="/assets/home/youtube.svg" alt="" width="32" />
                        <img src="/assets/home/telegram.svg" alt="" width="32" />
                        <img src="/assets/home/envelope-solid.svg" alt="" width="32" />
                        <img src="/assets/home/square-instagram.svg" alt="" width="32" />
                      </div>
                      <div class="w-full">
                        <button class="w-full">
                          <router-link to="/"
                            class="text-white bg-pinkHover py-1.5 px-md-4 px-2 rounded font-semibold w-full block">Join
                            GDCC
                            Community Now</router-link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="today-market-table">
              <div class="grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 mt-16 gap-5">
                <div class="flex items-center justify-center mb-4 mb-md-0 mb-lg-5 mb-xl-0">
                  <img src="/assets/home/crypto-anywhere.png" alt="Trade Crypto Anywhere Anytime" width=""
                    class="w-full md:w-474 lg:w-608" />
                </div>
                <div class="flex flex-col justify-center">
                  <div class="flex items-center justify-between mb-3">
                    <p class="text-24 lg:text-32 xl-text-40 font-bold">
                      Trade Crypto Anywhere,
                      <br class="d-block d-lg-none d-xl-block" />
                      Anytime
                    </p>
                  </div>
                  <!-- <p class="text-18  whitespace-pre" style="font-weight: 500;"> -->
                  <div class="row font-semibold text-center">
                    <div class="col-sm-6 col-md-4 cols-lg col-xl-4 mb-2">
                      <div
                        class="py-3 hover:bg-main-gray hover:shadow rounded flex flex-col justify-center items-center px-2 transition-all hover:text-[#ce1db4]">
                        <img src="/assets/24.gif" alt="" width="55" height="55" />
                        <p class="mt-2 text-18" style="font-weight: 500">
                          24/7 Access
                        </p>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 cols-lg col-xl-4 mb-2">
                      <div
                        class="py-3 hover:bg-main-gray hover:shadow rounded flex flex-col justify-center items-center px-2 transition-all hover:text-[#ce1db4]">
                        <img src="/assets/global.gif" alt="" width="55" height="55" />
                        <p class="mt-2 text-18">Global Reach</p>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 cols-lg col-xl-4 mb-2">
                      <div
                        class="py-3 hover:bg-main-gray hover:shadow rounded flex flex-col justify-center items-center px-2 transition-all hover:text-[#ce1db4]">
                        <img src="/assets/use.gif" alt="" width="55" height="55" />
                        <p class="mt-2 text-18">Easy to Use</p>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-4 cols-lg col-xl-4 mb-2">
                      <div
                        class="py-3 hover:bg-main-gray hover:shadow rounded flex flex-col justify-center items-center px-2 transition-all hover:text-[#ce1db4]">
                        <img src="/assets/secure2.gif" alt="" width="55" height="55" />
                        <p class="mt-2 text-18">Secure Transactions</p>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4 cols-lg col-xl-4 mb-2">
                      <div
                        class="py-3 hover:bg-main-gray hover:shadow rounded flex flex-col justify-center items-center px-2 transition-all hover:text-[#ce1db4]">
                        <img src="/assets/real.gif" alt="" width="55" height="55" />
                        <p class="mt-2 text-18">Real-Time Insights</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="today-market-table py-6 md:mt-10">
              <div class="flex items-center justify-between lg:py-0">
                <p class="section_heading pb-3">FAQ about GDCC</p>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div class="bg-white rounded-xl">
                  <details class="py-8 px-3 bg-main-gray group border border-gray-100 rounded-lg">
                    <summary class="flex items-center justify-between cursor-pointer">
                      <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                        1. What is GDCC Exchange?
                      </h5>

                      <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </summary>

                    <p class="lg:mt-4 leading-relaxed text-gray-700 font-semibold">
                      GDCC Exchange is a secure, user-friendly cryptocurrency
                      trading platform that offers a wide range of digital
                      assets. We provide a seamless experience for trading,
                      investing, and growing your cryptocurrency portfolio, with
                      low fees and top-notch security.
                    </p>
                  </details>

                  <details class="py-8 px-3 bg-main-gray group border border-gray-100 rounded-lg mt-6">
                    <summary class="flex items-center justify-between cursor-pointer">
                      <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                        2. How do I get started on GDCC Exchange?
                      </h5>

                      <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </summary>

                    <p class="lg:mt-4 leading-relaxed text-gray-700 font-semibold">
                      To get started, simply sign up by creating an account with
                      your email address. Once registered, you can deposit funds
                      (crypto or fiat), explore available markets, and start
                      trading. It’s quick and easy to get started!
                    </p>
                  </details>
                  <details class="py-8 px-3 bg-main-gray group border border-gray-100 rounded-lg mt-6">
                    <summary class="flex items-center justify-between cursor-pointer">
                      <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                        3. What cryptocurrencies can I trade on GDCC Exchange?
                      </h5>

                      <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </summary>

                    <p class="mt-4 leading-relaxed text-gray-700 font-semibold">
                      GDCC Exchange supports a wide variety of cryptocurrencies,
                      including popular options like Bitcoin (BTC), Ethereum
                      (ETH), Litecoin (LTC), GDCC and many altcoins. We offer
                      numerous trading pairs to give you more flexibility in
                      your trades.
                    </p>
                  </details>
                </div>
                <div class="bg-white rounded-xl">
                  <details class="py-8 px-3 bg-main-gray group border border-gray-100 rounded-lg">
                    <summary class="flex items-center justify-between cursor-pointer">
                      <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                        4. Is GDCC Exchange secure?
                      </h5>

                      <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </summary>

                    <p class="mt-4 leading-relaxed text-gray-700 font-semibold">
                      Yes! Security is our top priority. GDCC Exchange employs
                      advanced encryption, cold storage for assets, two-factor
                      authentication (2FA), and other industry-leading
                      protections to ensure your funds and personal data remain
                      secure.
                    </p>
                  </details>

                  <details class="py-8 px-3 bg-main-gray group border border-gray-100 rounded-lg mt-6">
                    <summary class="flex items-center justify-between cursor-pointer">
                      <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                        5. What are the fees on GDCC Exchange?
                      </h5>

                      <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </summary>

                    <p class="mt-4 leading-relaxed text-gray-700 font-semibold">
                      GDCC Exchange offers some of the lowest fees in the
                      industry. Fees vary based on your trading volume, but we
                      strive to keep costs as low as possible to give you more
                      value for your trades.
                    </p>
                  </details>

                  <details class="py-8 px-3 bg-main-gray group border border-gray-100 rounded-lg mt-6">
                    <summary class="flex items-center justify-between cursor-pointer">
                      <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                        6. How can I contact GDCC Exchange customer support?
                      </h5>

                      <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg"
                          class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                    </summary>

                    <p class="mt-4 leading-relaxed text-gray-700 font-semibold">
                      You can reach our customer support team 24/7 via live
                      chat, email, or through the support section of the
                      website. Our dedicated team is always ready to assist you
                      with any questions or concerns.
                    </p>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12 p-xl-0">
            <div class="register__now rounded-4">
              <div class="row">
                <div class="col-md-12 text-center">
                  <img src="/assets/gdc-logo.png" alt="logo" class="mx-auto mb-3" width="140" />
                  <h5 class="text-18 text-white">
                    Start Trading Today! <br class="d-none d-lg-block" />
                    Sign up now and be part of the next generation <br />
                    of crypto traders.
                  </h5>
                  <router-link to="/signup">
                    <button class="py-1.5 px-4 rounded font-semibold bg-pinkHover text-16 rounded-3 mt-4 text-white">
                      Start Now
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
// import { Carousel } from "vue3-carousel";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import HomeTabs from "../components/HomeTabs.vue";
import CryptoMarketVIew from "@/components/CryptoMarketVIew.vue";
import { gsap } from "gsap";
import ApiClass from "@/api";
export default {
  name: "HomeView",
  components: {
    Carousel,
    Slide,
    Navigation,
    // TabHover,
    HomeTabs,
    CryptoMarketVIew,
  },
  data() {
    return {
      showAnimation: false,
      darkactiveTab: "tab1",
      breakpoints1: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
        },
        768: {
          itemsToShow: 2,
          snapAlign: "start",
          margin: 30,
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "center",
          margin: 10,
        },
        1200: {
          itemsToShow: 4,
          snapAlign: "center",
          margin: 10,
        },
      },
      breakpoints2: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
        },
        700: {
          itemsToShow: 1,
          snapAlign: "start",
          margin: 10,
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
          margin: 10,
        },
        1500: {
          itemsToShow: 2,
          snapAlign: "start",
          margin: 10,
        },
      },
      breakpoints3: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
          vertical: true,
        },
        700: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
          vertical: true,
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
          vertical: true,
        },
      },

      // activityListDummy: [
      //   {
      //     image: "",
      //   },
      //   {
      //     image: "",
      //   },
      //   {
      //     image: "",
      //   },
      //   {
      //     image: "",
      //   }
      // ],
      steps: [
        {
          title: "01. Sign Up",
          image: "/assets/home/step-1.svg",
          description:
            "Register in just minutes—no complex verification needed to get started.",
          buttonText: "Sign Up",
          buttonLink: "/signup",
        },
        {
          title: "02. Deposit Funds",
          image: "/assets/home/step-2.svg",
          description:
            "Easily deposit your crypto or fiat and start trading right away.",
          buttons: [
            {
              text: "Deposit Crypto",
              link: "/deposit/USDT",
              route: "/login",
            },
            {
              text: "Buy Crypto",
              link: "/p2p",
              route: "/login",
            },
          ],
        },
        {
          title: "03. Trade & Grow",
          image: "/assets/home/step-3.svg",
          description:
            "Explore hundreds of trading pairs, execute orders, and watch your portfolio grow!",
          buttonText: "Trade",
          buttonLink: "/spot/BTCUSDT",
        },
      ],
      reviewoptions: [
        {
          img: "activities-1.jpg",
          name: "Binner, Google User",
          title:
            'GDCC Exchange has been a game-changer for my trading. The platform is easy to use, secure, and offers great liquidity. I love the low fees and fast transactions. Highly recommended!" — Sarah M., Crypto Trader',
        },
        {
          img: "activities-2.jpg",
          name: "Binner, Google User",
          title:
            'As a beginner, GDCC Exchange made it easy for me to understand crypto trading. The customer support team is responsive, and the tools are top-notch. I’ve been able to grow my portfolio with confidence." — Michal D., New Investor',
        },
        {
          img: "activities-3.jpg",
          name: "Binner, Google User",
          title:
            'GDCC Exchange has been a game-changer for my trading. The platform is easy to use, secure, and offers great liquidity. I love the low fees and fast transactions. Highly recommended!" — Sarah M., Crypto Trader',
        },
        {
          img: "activities-4.jpg",
          name: "Binner, Google User",
          title:
            'As a beginner, GDCC Exchange made it easy for me to understand crypto trading. The customer support team is responsive, and the tools are top-notch. I’ve been able to grow my portfolio with confidence." — Michal D., New Investor',
        },
      ],
      mainoptions: [
        {
          img: "product-1.png",
          name: "Binner, Google User",
        },
        {
          img: "product-2.png",
          name: "Binner, Google User",
        },
        {
          img: "product-3.png",
          name: "Binner, Google User",
        },
      ],
      email: "",
      isLoggedIn: false,
    };
  },
  mounted() {
    const items = gsap.utils.toArray(".item");
    const tl = gsap.timeline();

    items.forEach((item) => {
      tl.from(item.querySelector(".first"), {
        opacity: 0,
        y: 50,
      }).from(item.querySelector(".second"), {
        opacity: 0,
        x: 50,
      });
    });

    setInterval(() => {
      this.showAnimation = !this.showAnimation;
    }, 1000);
    this.getActivites();
  },
  methods: {
    gotoSignup() {
      if (this.email) {
        localStorage.setItem("homePageEmail", JSON.stringify(this.email));
      }
      this.$router.push("/signup");
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(100px)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.2,
      });
    },
    getRedirectLink(button) {
      this.isLoggedIn = localStorage.getItem("token") || false;
      if (this.isLoggedIn) {
        return button.link;
      } else {
        return button.route;
      }
    },
    async getActivites() {
      try {
        const allBanners = await ApiClass.getAdminRequest(
          "admin/banner/get",
          false
        );
        if (allBanners?.status_code == "1") {
          this.activityList = allBanners?.data;
          return;
        }
      } catch (error) {
        return this.failed(error.message);
      }
    },
  },
};
</script>

<style scoped>
.carousel__icon {
  fill: #fff !important;
}

section {
  padding: 45px 0;
}

.section_heading {
  font-size: 32px;
  font-weight: 700;
}

.get_start {
  flex-direction: column;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.banner_sec {
  min-height: 78vh;
  background-image: url(/public/banner-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #010220;

  /* background-image: url(../../public/home/Home-Hero.png); */
  background-position: 50% 20%;
}

/* .banner_sec::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
} */
.banner_heading {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
}

.banner_box h6 {
  color: green;
  font-family: "Inter", Sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

.banner_box h1 {
  color: white;
  font-family: "Raleway", Sans-serif;
  font-size: 60px;
  font-weight: 600;
}

.banner_box p {
  color: #ccc;
  font-size: 18px;
}

.banner_img {
  background-image: url(/public/bImg-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

/* Banner css end  */

.register__now {
  background-image: linear-gradient(90deg, #2b0d35 0%, #09081d 100%);

  /* linear-gradient(90deg, #c61cbd 0%, #a616db 100%) ; */
  /* linear-gradient(90deg, #E75469 0%, #A93EF8 100%) */
  position: relative;
  padding: 60px 80px 60px 80px;
  overflow: hidden;
}

/* .register__now h5{
  line-height: 59px;
  font-size: 38px;
} */
.register__now h5 {
  line-height: 57px;
  font-size: 36px;
  font-weight: 700;
}

.register__now p {
  color: #020710;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.9em;
}

.feature__sec .features__box {
  background-color: #25283d;
  padding: 40px 40px 40px 40px;
  margin: 20px 20px 20px 20px;
}

.dark__bg {
  /* background-image: url(/public/concept-bg.png); */
  background-image: url(/public/feature-bg.png);
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cus__card {
  background: #131325;
}

@keyframes rollIn {
  0% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0%);
  }
}

.animate-roll-in {
  animation: rollIn 1s ease-in-out forwards;
}

.crypto-use:hover img {
  filter: brightness(0) saturate(100%) invert(28%) sepia(97%) saturate(3789%) hue-rotate(222deg) brightness(92%) contrast(97%);
}

.bg-gatepay {
  background: url("../../public/assets/home/gate-pay.webp");
}

.get__start {
  min-height: 275px;
}

.get__start p {
  font-size: 17px;
}

@media all and (min-width: 1400px) and (max-width: 1699px) {
  .why__box {
    background: #120a28;
    color: #fff;
    min-height: 375px;
  }
}

@media all and (min-width: 1200px) and (max-width: 1399px) {
  .banner_sec {
    min-height: 70vh;
  }

  .cus__btn {
    font-size: 13px;
    padding: 5px 17px !important;
  }

  .get__start {
    min-height: 280px;
  }

  .dark__bg {
    height: auto;
  }

  .why__box h6 {
    font-size: 21px;
    font-weight: 500 !important;
  }

  .why__box {
    min-height: 365px;
  }
}

@media all and (min-width: 992px) and (max-width: 1199px) {
  .banner_sec {
    min-height: 65vh;
  }

  .cus__btn {
    font-size: 12px;
    padding: 4px 7px !important;
  }

  .get__start {
    min-height: 325px;
  }

  .dark__bg {
    height: auto;
  }

  .register__now h5 {
    line-height: 46px;
    font-size: 30px;
  }

  .why__box h6 {
    font-size: 21px;
    font-weight: 500 !important;
  }

  .why__box {
    min-height: 365px;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .banner_img {
    display: none;
  }

  .banner_sec {
    min-height: 60vh;
  }

  .dark__bg {
    height: auto;
  }

  .register__now h5 {
    line-height: 35px;
    font-size: 29px;
    font-weight: 500 !important;
  }

  .register__now {
    padding: 30px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .banner_sec {
    min-height: auto;
  }

  .banner_heading {
    font-size: 28px;
    line-height: 50px;
  }

  .w-50 {
    width: 100% !important;
  }

  .whitespace-pre {
    white-space: break-spaces;
  }

  .section_heading {
    font-size: 24px;
  }

  .dark__bg {
    height: auto;
  }

  .register__now h5 {
    line-height: 38px;
    font-size: 26px;
    font-weight: 500 !important;
  }

  .register__now {
    padding: 20px 20px;
  }
}

@media all and (min-width: 576px) and (max-width: 767px) {
  .get__start {
    min-height: 310px;
  }
}

/* @media all and (min-width: 1024px) and (max-width: 1199px) {

    .container-lg, .container-md, .container-sm, .container {
        max-width: 960px !important;
    }
} */

/* @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
}

@media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px;
    }
}

@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 720px;
    }
}
@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px;
    }
} */
@media (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1536px;
  }
}
</style>
