<template>
  <div v-if="!isLoading">

    <div v-if="$route.meta.authOnly">
      <MainHeader />
      <div id="myview" @click="hideDropdowns">
        <router-view />
      </div>
    </div>

    <template v-else-if="$route.meta.contentOnly">
      <div class="content-only-view">
        <router-view />
      </div>
    </template>

    <template v-else>
      <MainHeader />
      <!-- <MainHeaderOld /> -->
      <div id="myview" @click="hideDropdowns">
        <router-view />
      </div>
      <MainFooter />
    </template>
  </div>

  <!-- Loading Screen -->
  <div v-else class="loading-screen">
    <img src="/assets/gdc-logo.png" alt="" width="75" class="loading-image zoom-glow">
  </div>

</template>

<script>
import MainHeader from './components/MainHeader.vue';
import MainFooter from './components/MainFooter.vue';
export default {
  name: 'HomeView',
  data() {
    return {
      isLoading: true
    }
  },
  components: {
    MainHeader,
    MainFooter,
  },
  mounted() {
    window.addEventListener('load', this.handleLoad)
  },

  methods: {
    handleLoad() {
      this.isLoading = false;
    },
  }
}

</script>

<style lang="scss">
.why__box{

  background:#120a28;
  color: #fff;
  min-height: 340px;
}
.why__box p{
  font-size: 17px;
}


// #myview{
//   min-height: 100vh
// }
.carousel__icon{
  fill: #fff !important;
}

.loading-image {
  width: 150px;
  height: auto;
}

/* More dramatic zoom and glow effect */
.zoom-glow {
  animation: dramaticZoomGlow 3s infinite;
}

@keyframes dramaticZoomGlow {
  0% {
    transform: scale(1.2);
    filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0)) brightness(1);
  }

  25% {
    transform: scale(0.8);
    filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.8)) brightness(1.2);
  }

  50% {
    transform: scale(1);
    filter: drop-shadow(0 0 50px rgba(255, 255, 255, 1)) brightness(1.5);
  }

  75% {
    transform: scale(0.9);
    filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.8)) brightness(1.2);
  }

  100% {
    transform: scale(1.2);
    filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0)) brightness(1);
  }
}

/* Alternative with color glow */
.color-zoom-glow {
  animation: colorZoomGlow 3s infinite;
}

@keyframes colorZoomGlow {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 10px rgba(66, 135, 245, 0.7));
  }

  25% {
    transform: scale(0.9);
    filter: drop-shadow(0 0 20px rgba(245, 66, 66, 0.8));
  }

  50% {
    transform: scale(0.8);
    filter: drop-shadow(0 0 30px rgba(66, 245, 93, 0.9));
  }

  75% {
    transform: scale(0.9);
    filter: drop-shadow(0 0 20px rgba(245, 66, 233, 0.8));
  }

  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 10px rgba(66, 135, 245, 0.7));
  }
}

/* Smooth zoom and glow */
.smooth-zoom-glow {
  animation: smoothZoomGlow 4s infinite ease-in-out;
}

@keyframes smoothZoomGlow {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5)) brightness(1);
  }

  50% {
    transform: scale(0.85);
    filter: drop-shadow(0 0 40px rgba(255, 255, 255, 0.8)) brightness(1.3);
  }

  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.5)) brightness(1);
  }
}






















.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  /* or your preferred color */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.select2 {
  width: 300px !important;
  height: 32px;
}

.select2-container .select2-selection--single {
  height: 42px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 42px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 7px !important;
}

.scrollbar-hide::-webkit-scrollbar {
  height: 5px !important;
}

.scrollbar-hide-h::-webkit-scrollbar {
  width: 3px !important;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: #d6d6d662;
  /* Customize scrollbar color */
  opacity: 0 !important;
}

.scrollbar-hide::-webkit-scrollbar-track {
  background-color: transparent;
  /* Customize scrollbar track color */
}

.scrollbar-hide::-webkit-scrollbar-thumb:hover {
  background-color: #bbb9b9;
  /* Customize scrollbar thumb color on hover */
}

.card.custom-Table .p-datatable-wrapper table {
  table-layout: fixed;
}

.card.custom-Table .p-datatable-wrapper table thead tr th {
  font-weight: 500;
  font-size: 12px;
  color: #8d93a6 !important;
  background-color: #f8fafd !important;
}

//.card.custom-Table .p-datatable-wrapper table tbody td{
// font-size: 12px;
// padding: 1.5rem;
//}

.card.custom-Table .p-datatable-wrapper table tbody tr:hover {
  background-color: #f2f7ff;
}

.card.custom-Table .p-datatable-wrapper table thead .p-column-header-content svg {
  height: 10px !important;
  widows: 10px !important;

}

.p-checkbox-box {
  border: 1px solid lightgray;
}

.focus\:border-pinkHover:focus {
  border-color: #ce1db4;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: #ce1db40f;
}

.p-checkbox.p-highlight .p-checkbox-box {
  background-color: #ce1db4;
}

.p-multiselect:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled).p-focus {
  outline: 1px solid #ce1db4 !important;

}

.p-multiselect-label section {
  color: #ce1db4;
}

.p-multiselect:not(.p-disabled).p-focus .p-icon.p-multiselect-trigger-icon,
.p-dropdown:not(.p-disabled).p-focus .p-icon.p-dropdown-trigger-icon {
  color: #ce1db4 !important;
  transform: rotate(180deg);

}

.p-multiselect-header .p-checkbox.p-component,
.p-multiselect-header .p-multiselect-close.p-link {
  display: none !important;

}

.p-multiselect-filter,
.p-dropdown-filter {
  padding: .3rem;
  background-color: #f5f5f7;
  border: 1px solid lightgray;
  margin-bottom: 1rem
}

.p-icon.p-multiselect-filter-icon,
.p-icon.p-dropdown-filter-icon {
  position: absolute;
  top: 18px;
}

.p-dropdown-filter:focus {
  border-color: #ce1db4;
}

.p-inputtext {
  font-size: 14px;
  color: #000;
}

.p-dropdown-item.p-highlight,
.p-multiselect-item.p-highlight {
  background-color: #ce1db40f;

}

.item-required-mark-optional::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

ul.VuePagination__pagination li.VuePagination__pagination-item button.page-link.active {
  background-color: #ce1db4 !important;
  border-color: #ce1db4 !important;
  color: white !important;
}


:root {
  --white: #fff;
  --black: #1E2026;
  --tab-bg: #FAFAFA;
  --active-green: #0ECB81;
  --active-red: #f6465d;
  --tab-border: #E6E8EA;
  --tab-active-shadow: rgb(0 0 0 / 25%) 1px 0px 4px;
  --tab-box-shadow: 0px 0px 1px rgb(24 26 32 / 10%), 0px 3px 6px rgb(71 77 87 / 4%), 0px 1px 2px rgb(24 26 32 / 4%);
  --active-yellow: #F0B90B;
  --light-yellow: #C99400;
  --grey: #76808F;
  --placeholder: #b3b4bc;
  --blue: #285C93;
  --filter-bg: #F5F5F5;
  --badge-yellow: rgb(254, 246, 216);
  --green: rgb(3, 166, 109);
  --badge-green: rgb(230, 255, 241);
  --author-color: rgb(40, 92, 147);
  --author-bg: rgb(70, 83, 200);
  --payment-badge: rgb(239 238 238);
  --market-border: rgb(245, 245, 245);
  --terms-text: rgb(118, 128, 143);
  --badge-border: #cdcdcd;
  --icon-bg: linear-gradient(90deg, rgba(255, 255, 255, 1) 5%, rgba(255, 254, 252, 1) 5%, rgba(240, 185, 11, 1) 100%);
  --bs-border-color: #dee2e6;
  --refresh-btn-bg: #EAECEF;

  /*=============== new ================= */
  --trans-yellow: #fef6d8;
  --btn-auth: #fcd535;


  /** FONT VARIABLE **/
  --fs-8: 0.5rem;
  --fs-10: 0.625rem;
  --fs-12: 0.75rem;
  --fs-13: 0.813rem;
  --fs-14: 0.875rem;
  --fs-15: 0.9375rem;
  --fs-16: 1rem;
  --fs-17: 1.063;
  --fs-18: 1.125rem;
  --fs-20: 1.25rem;
  --fs-22: 1.375rem;
  --fs-24: 1.5rem;
  --fs-25: 1.5625rem;
  --fs-30: 1.875rem;
  --fs-33: 2.0625rem;
  --fs-35: 2.188rem;
  --fs-40: 2.5rem;
  --fs-45: 2.8125rem;
  --fs-50: 3.125rem;
  --fs-55: 3.438rem;
}

.text-red {
  color: var(--active-red) !important;
}

.text-green {
  color: var(--green) !important;
}

.text-grey {
  color: var(--grey) !important;
}


/* multiselet css */

.select_region_col .multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  background: var(--active-yellow);
  margin: 3px 3px 8px;
}

.select_region_col .multiselect-placeholder {
  font-size: var(--fs-14);
}

.select_region_col .multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid var(--bs-border-color);
}

.select_region_col .multiselect-tag.is-user i:before {
  color: var(--white);
  border-radius: 50%;
  ;
}

.select_region_col .user-image {
  margin: 0 6px 0 0;
  border-radius: 50%;
  height: 22px;
}

.select_region_col .multiselect-dropdown .multiselect-option {
  gap: 5px !important;
  font-size: var(--fs-13);
}

.select_region_col .multiselect.is-active {
  box-shadow: unset !important;
}



ul.VuePagination__pagination {
  gap: 7px;
  display: flex !important;
}

ul.VuePagination__pagination li.VuePagination__pagination-item button.page-link {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: var(--black);
  line-height: 0;
  font-weight: 600;
  font-size: var(--fs-14);
  background-color: var(--filter-bg);
  border-color: var(--filter-bg);
}

ul.VuePagination__pagination li.VuePagination__pagination-item button.page-link:focus {
  box-shadow: none;
}

ul.VuePagination__pagination li.VuePagination__pagination-item button.page-link.active {
  background-color: var(--active-yellow);
  border-color: var(--active-yellow);
}

.select_payment_col .multiselect {
  border: 1px solid var(--active-yellow)
}

.select_payment_col .multiselect-option {
  display: flex;
}

.select_payment_col .multiselect-tag {
  background: unset;
  color: var(--black);
}

/* multiselect */

.select_payment_col .multiselect_box .multiselect__content-wrapper {
  border: 1px solid var(--bs-warning);
}

.select_payment_col .multiselect_box .multiselect__content-wrapper {
  max-height: 310px;
  width: 514px;
}

.select_payment_col .multiselect_box .multiselect--above .multiselect__content-wrapper {
  border-bottom: 1px solid var(--active-yellow);
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  bottom: -120px;
  left: -1px;
}

.select_payment_col .multiselect_box .multiselect__tag-icon::after {
  color: var(--white);
}

.select_payment_col .multiselect_box .multiselect__tag-icon:hover::after {
  color: var(--black);
}

.select_payment_col .multiselect_box .multiselect__option--highlight {
  background: var(--active-yellow);
}

.select_payment_col .multiselect_box .multiselect__option--highlight::after {
  background: var(--active-yellow);
}

.select_payment_col .multiselect_box .multiselect__tag {
  background: var(--active-yellow);
}

.select_payment_col .multiselect_box .multiselect__tags {
  width: 100%;
}



/*===================== AUTH CSS START====================== */
.auth_page {
  min-height: 85vh;
}

.main_auth_box .auth_head h3 {
  font-weight: 600;
  font-size: var(--fs-30);
  line-height: 40px;
  color: var(--black);
}

.form_box .form_body label {
  color: var(--black);
  font-size: var(--fs-14);
  font-weight: 500;
  margin-bottom: 5px;
}

.form_box .form_body .form-control {
  color: var(--black);
  font-size: var(--fs-13);
  font-weight: 500;
  padding: 10px 10px;
  border: 1px solid rgb(234, 236, 239);
}

.form_box .form_body input::placeholder {
  color: var(--black);
  font-size: var(--fs-13);
  font-weight: 500;
}

.form_box .form_body .btn_next {
  color: var(--black);
  font-size: var(--fs-15);
  font-weight: 500;
  padding: 10px 10px;
  background-color: var(--btn-auth);
  border-radius: 5px;
}

.form_box .form_body a {
  color: var(--light-yellow);
  font-size: var(--fs-14);
  font-weight: 500;
}

/* ===================== AUTH CSS END====================== */

/* loader css */
.spinner-border {
  height: 22px !important;
  width: 22px !important;
}


@media all and (min-width:992px) and (max-width:1024px) {
  .multiselect__content-wrapper {
    width: 362px;
  }
  // .hidden{
  //   display: block !important;
  // }
}

@media all and (min-width:768px) and (max-width:991px) {
  // .hidden{
  //   display: block !important;
  // }
  .multiselect__content-wrapper {
    width: 264px;
  }
}

@media all and (min-width:320px) and (max-width:767px) {

  .multiselect__content-wrapper {
    width: 270px;
  }
}

a {
  text-decoration: none !important;
}

.border-none {
  border: none !important;
}

.width-fit-content {
  width: fit-content !important;
}

.border-pinkHover {
  border: 1px solid #ce1db4 !important;
}

a {
  color: black !important;
}

.text-graylight {
  color: #7e8494 !important;
}

.text-white {
  color: white !important
}

.border-pink {
  border-color: #ce1db4 !important;
}

.height-fit-content {
  height: fit-content !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-base {
  color: rgb(156 163 175) !important;
}

.text-pinkHover {
  color: #ce1db4 !important;
}
.box-tab {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px
}
</style>
